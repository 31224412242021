export const SITE_CONFIG = {
  title: "Cày Phim",
  titleShowed: false,
  home: "Cày Phim | Xem Phim Online tốc độ cao | Cập nhật phim mới sớm nhất",
  phimle:
    "Cày Phim | Trang phim lẻ | Trang Phim hay nhất, cập nhật phim mới mỗi ngày",
  phimbo:
    "Cày Phim | Trang phim bộ | Trang Phim hay nhất, cập nhật phim mới mỗi ngày",
  phimchieurap:
    "Cày Phim | Trang phim chiếu rạp | Trang Phim hay nhất, cập nhật phim mới mỗi ngày",
  phimmoicapnhat:
    "Cày Phim | Trang phim mới cập nhật | Trang Phim hay nhất, cập nhật phim mới mỗi ngày",
  description:
    "CayPhim - NghienPhim - Trang xem phim online Full HD nhanh nhất Việt Nam, thuyết minh, phụ đề, VietSub những bộ phim ngôn tình, tình cảm lãng mạn, hài hước, hành động",
  icon: "/nghienphim.png",
};
